<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3>투표 생성</h3>
      </div>
    </template>

    <template #modalBody>
      <div class="info-form">
        <h5>투표 주제</h5>
        <input-basic-new
          v-for="(item, index) in localizations"
          :key="`lang-${index}`"
          class="title"
          :label="item.label"
          :required="item.lang === 'ko'"
          placeholder="투표 주제를 자유롭게 입력해주세요"
          :default-value="state.pollForm.localizations[item.lang]['title']"
          @updateData="
            (value) =>
              actions.updateLocalizationsOfPollForm(item.lang, 'title', value)
          "
        >
        </input-basic-new>

        <h5>투표 설명</h5>
        <input-basic-new
          v-for="(item, index) in localizations"
          :key="`lang-${index}`"
          class="desc"
          :label="item.label"
          placeholder="투표에 대해 설명해주세요"
          :default-value="
            state.pollForm.localizations[item.lang]['description']
          "
          @updateData="
            (value) =>
              actions.updateLocalizationsOfPollForm(
                item.lang,
                'description',
                value
              )
          "
        >
        </input-basic-new>
      </div>
    </template>
    <template #modalFooter>
      <div class="button-wrapper">
        <button-basic
          class="cancel"
          text="취소"
          text-size="s1"
          bg-color="#ECF1F4"
          color="#0d0d0d"
          :disabled="state.isCreating"
          @action="actions.closeModal()"
        ></button-basic>
        <button-basic
          class="save"
          text="생성"
          text-size="s1"
          :is-loading="state.isCreating"
          :disabled="!state.isActiveSaveBtn || state.isCreating"
          @action="actions.createPoll()"
        ></button-basic>
        <!--        :disabled="!state.isActiveSaveBtn || state.isCreating"-->
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import { reactive, computed } from "vue";
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import InputBasicNew from "../../../../components/console/inputs/InputBasicNew";
import localizations from "@/consts/poll";

export default {
  name: "PollCreateModal",
  components: {
    ButtonBasic,
    BaseModalSide,
    InputBasicNew,
  },
  emits: ["hideModal", "saveComplete"],
  setup(props, { emit }) {
    const state = reactive({
      pollForm: {
        localizations: {
          ko: { title: "", description: "" },
          en: { title: "", description: "" },
          ja: { title: "", description: "" },
          zh_cn: { title: "", description: "" },
          zh_tw: { title: "", description: "" },
        },
      },
      isActiveSaveBtn: computed(() => {
        return state.pollForm.localizations.ko.title.length > 0;
      }),
      isCreating: false,
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      updateLocalizationsOfPollForm: (lang, formKey, value) => {
        state.pollForm.localizations[lang][formKey] = value;
      },
      createPoll: () => {
        state.isCreating = true;
        emit("saveComplete", state.pollForm);
      },
    };

    return { state, actions, localizations };
  },
};
</script>

<style src="./style.css" scoped></style>
