<template>
  <div>
    <div class="poll">
      <div class="bg-gray-01 poll-item">
        <div class="col-1" @click="actions.goToEditPoll()">
          <span class="sub-text-s2 title">
            {{ state.title }}
          </span>
        </div>

        <div class="col-2">
          <span class="sub-text-s2">{{ state.startAt }}</span>
        </div>

        <div class="col-3">
          <span class="sub-text-s2">{{ state.finishAt }}</span>
        </div>

        <div class="col-4">
          <span class="sub-text-s2">-</span>
        </div>
        <div class="col-5"></div>
        <div class="col-6">
          <span class="sub-text-s2">{{ pollStatus }}</span>
        </div>
        <div class="col-7">
          <button-text
            text="미리 보기"
            text-size="s2"
            padding="0"
            text-weight="text"
            :is-icon="true"
            icon-position="back"
            @click="actions.moveToVotePage()"
          >
            <template #icon>
              <export-icon fill-color="#0D0D10"></export-icon>
            </template>
          </button-text>
          <button
            v-if="state.poll.pollOptions.length > 0"
            class="show-polls-option-btn"
            @click="actions.togglePollOptionsList()"
          >
            <arrow-icon
              width="16"
              height="16"
              :direction="state.showPollOptionsList ? 'up' : 'down'"
            ></arrow-icon>
          </button>
        </div>
      </div>
      <div ref="pollOptions" class="poll-options bg-gray-01">
        <div class="bg-gray-studio">
          <div class="poll-options-label b-text-3 text-gray-third table">
            <div class="col-1">선택지명</div>
            <div class="col-2">득표수</div>
            <div class="col-3">득표율</div>
          </div>

          <ul class="poll-options-list">
            <li
              v-for="(pollOption, index) in state.poll.pollOptions"
              :key="`pollOption-${index}`"
              class="table sub-text-s2"
            >
              <div class="title col-1">
                <img :src="pollOption.featuredImage" :alt="pollOption.title" />
                <div>{{ pollOption.title }}</div>
              </div>
              <div class="vote-count col-2">
                {{ pollOption.voteCount }}
              </div>
              <div class="vote-percentage col-3">
                {{ pollOption.votePercentage }}%
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import helper from "@/helper";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import ExportIcon from "@/components/console/icons/ExportIcon.vue";

export default {
  name: "PollItem",
  components: { ExportIcon, ArrowIcon, ButtonText },
  props: {
    poll: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
  },
  emits: ["completeCopy"],
  setup(props, { emit }) {
    const pollOptions = ref();

    const router = useRouter();

    const state = reactive({
      title: computed(() => {
        return state.poll.title
          ? state.poll.title
          : state.poll.localization["en"].title;
      }),
      startAt: computed(() => {
        return state.poll.startAt
          ? helper.dateTimeFormat(state.poll.startAt)
          : "-";
      }),
      finishAt: computed(() => {
        return state.poll.finishAt
          ? helper.dateTimeFormat(state.poll.finishAt)
          : "-";
      }),
      poll: {},
      showPollOptionsList: false,
      voteCount: computed(() => {
        let candidateVoteCount = props.poll.voteCount
          ? props.poll.voteCount
          : 0;
        return candidateVoteCount;
      }),
    });

    onBeforeMount(() => {
      state.poll = props.poll;
    });

    const pollStatus = computed(() => {
      const statusMessages = {
        0: "예정",
        1: "예정",
        5: "종료",
        7: "긴급 종료",
      };

      return statusMessages[state.poll.status] || "진행중";
    });

    const actions = {
      goToEditPoll: () => {
        console.log(state.poll.resourceId);
        router.push({
          name: `console.polls.edit`,
          params: {
            pollResourceId: state.poll.resourceId,
          },
        });
      },
      togglePollOptionsList: () => {
        state.showPollOptionsList = !state.showPollOptionsList;
        if (!state.showPollOptionsList) {
          pollOptions.value.style.maxHeight = null;
        } else {
          pollOptions.value.style.maxHeight = `${pollOptions.value.scrollHeight}px`;
        }
      },
      votePercentage: (voteCount) => {
        let fullVoteCount = state.voteCount;
        let candidateVoteCount = voteCount;
        return candidateVoteCount ? fullVoteCount / candidateVoteCount : 0;
      },
      moveToVotePage: () => {
        const voteUrl = `${process.env.VUE_APP_VOTE_URL}/votes/${state.poll.resourceId}`;
        window.open(voteUrl, "_blank");
      },
    };

    return { state, actions, pollOptions, pollStatus };
  },
};
</script>

<style src="./style.css" scoped></style>
