<template>
  <div class="page-container-new">
    <page-loading v-if="state.pageLoading"></page-loading>

    <div v-if="!state.pageLoading">
      <page-header
        title="투표 관리"
        description="팔로워의 의견을 듣고 싶은 주제가 있다면 투표를 만들어 보세요"
      ></page-header>

      <div class="container-body">
        <div class="tab-menu">
          <div class="tab-section">
            <button class="selling-tab sub-title-s1 active">
              {{ `투표 목록 ${state.polls.length}` }}
            </button>
          </div>
          <div>
            <button-basic
              class="create-btn"
              text="투표 생성"
              icon-position="front"
              padding="8px 16px"
              @click="actions.openPollCreateModal()"
            >
              <template #icon>
                <plus-icon fill-color="#ffffff"></plus-icon>
              </template>
            </button-basic>
          </div>
        </div>

        <div class="bg-gray-01 sub-text-s3 text-gray-second poll-list-label">
          <span class="col-1">투표 주제</span>
          <span class="col-2">시작 일시</span>
          <span class="col-3">종료 일시</span>
          <span class="col-4">마지막 수정 날짜</span>
          <span class="col-5">캡챠 인증 여부</span>
          <span class="col-6">상태</span>
          <span class="col-7"></span>
        </div>

        <ul v-if="state.polls.length > 0" class="poll-list">
          <li
            v-for="(poll, index) in state.polls"
            :key="`poll-${poll.resourceId}`"
          >
            <poll-item :poll="poll" :index="index"></poll-item>
          </li>
        </ul>
        <empty-list
          v-if="state.polls.length === 0"
          class="empty-list"
          :text="`생성된 투표가 아직 없어요.<br />투표를 만들어 팔로워 의견을
              알아보세요.`"
          btn-text="투표 생성하기"
          :is-info-icon="true"
          :is-button="true"
          @btnAction="actions.openPollCreateModal()"
        >
          <template #buttonIcon>
            <arrow-icon class="arrow-icon" stroke-size="normal"></arrow-icon>
          </template>
        </empty-list>
      </div>

      <div
        v-if="state.pollsMeta.pagination.lastPage > 1"
        class="pagination-wrapper"
      >
        <pagination
          :pagination="state.pollsMeta.pagination"
          @getPageData="(page) => actions.updateFilters(page)"
        ></pagination>
      </div>
    </div>
  </div>
  <teleport :disabled="true">
    <poll-create-modal
      v-if="state.showPollCreateModal"
      @hide-modal="actions.closePollCreateModal()"
      @save-complete="(value) => actions.createPoll(value)"
    >
    </poll-create-modal>
  </teleport>
</template>

<script>
import { computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import PageHeader from "../../../components/console/headers/PageHeader";
import PageLoading from "../../../components/console/loadings/PageLoading";
import PollItem from "@/pages/console/Polls/PollItem/PollItem";
import ButtonBasic from "../../../components/console/buttons/ButtonBasic";
import swal from "@/helper/swal";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";
import { useRoute, useRouter } from "vue-router";
import PollCreateModal from "@/pages/console/Polls/PollCreateModal/PollCreateModal.vue";
import Pagination from "@/components/console/pagination/Pagination.vue";
import EmptyList from "@/components/console/empties/EmptyList.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import { updateEmptyStringsToNull } from "@/helper/poll";
import { cloneDeep } from "lodash";

export default {
  name: "Polls",
  components: {
    ArrowIcon,
    EmptyList,
    Pagination,
    PollCreateModal,
    PlusIcon,
    PollItem,
    PageLoading,
    PageHeader,
    ButtonBasic,
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const store = useStore();

    const state = reactive({
      polls: computed(() => {
        return store.getters["polls/getPolls"];
      }),
      showPollCreateModal: false,
      pageLoading: true,
      pollsMeta: computed(() => {
        return store.getters["polls/getPollsMeta"];
      }),
      filters: {
        page: 1,
        perPage: 15,
      },
    });

    const getPolls = () => {
      state.pageLoading = true;
      store.dispatch("polls/getPolls", state.filters).then(() => {
        state.pageLoading = false;
      });
    };

    watch(
      () => route.query,
      async (query) => {
        const { perPage, page } = query;
        state.filters = { ...state.filters, perPage, page };
        await getPolls();
      },
      { immediate: true }
    );

    const moveRouteQueryParameter = () => {
      router.push({ path: "/console/polls", query: state.filters });
    };

    const actions = {
      createPoll: (pollForm) => {
        const cloneDeepForm = cloneDeep(pollForm);

        for (const key in cloneDeepForm.localizations) {
          const localization = cloneDeepForm.localizations[key];
          updateEmptyStringsToNull(localization, ["title", "description"]);
        }

        let payload = [];
        payload[0] = cloneDeepForm;

        store
          .dispatch("polls/postPoll", { data: payload, filters: state.filters })
          .then(() => {
            state.showPollCreateModal = false;
            swal.createCompleteToast();
          });
      },
      openPollCreateModal: () => {
        state.showPollCreateModal = true;
      },
      closePollCreateModal: () => {
        state.showPollCreateModal = false;
      },
      updateFilters: (page) => {
        state.filters = { ...state.filters, page };
        moveRouteQueryParameter();
      },
    };

    return { state, actions };
  },
};
</script>

<style scoped src="./style.css"></style>
